@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./style/editor.css";

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Light.subset.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Regular.subset.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Medium.subset.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-SemiBold.subset.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard-Bold.subset.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

/* * {
  line-height: 1;
} */

button {
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
  display: flex;
}


.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
}




@layer base {
  :root {
    /* white */
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%; /* black */

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    /* background */
    --background: 210 100% 99%;
    --background-footer: 215 19% 18%;

    /* brand color */
    --primary: 251 82% 65%;
    --primary-light: 245 91% 77%;
    --primary-dark: 252 77% 56%;
    --primary-100: 250 67% 93%;

    /*** text color ***/
    --base-white: : 0 0% 100%;
    --base-50: 0.98 0.01 236.97;
    --base-100: 0.96 0.01 232.55;
    --base-200: 0.91 0.01 232.55;
    --base-300: 0.88 0.01 226.96;
    --base-400: 0.67 0.03 242.39;
    --base-500: 0.5 0.05 246.21;
    --base-600: 0.38 0.04 248.78;
    --base-700: 0.29 0.03 252.87;
    --base-800: 0.18 0.03 257.56;
    --base-900: 0.12 0.01 268.54;
    --base-1000: 0.04 0.01 0;

    /* error color*/
    --error: 0 97% 59%;
    --error-light: 0 97% 71%;
    --error-dark: 0 70% 50%;

    /* point color */
    --point-green: 173 79% 47%;
    --point-green-light: 173 79% 47% 0.08%;
    --point-pink: 324 79% 61%;
    --point-yellow: 47 98% 67%;
    --point-yellow-light: 47 98% 67% 0.08%;
    --point-blue: 215 89% 62%;
    --point-blue-middle: 212 100% 66%;
    --point-blue-light: 215 89% 62% 0.08%;
    --point-red: 3 100% 69%;
    --point-red-light: 3 100% 69% 0.08%;
    --point-purple: 277 84% 64%;
    --point-purple-light: 277 84% 64% 0.08%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.ql-editor{
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
     font-size: 16px;
}


/* 특정 클래스에 초기화 스타일 제외 */
.no-reset * {
  all: revert;
}
.no-reset blockquote {
  all: unset; /* blockquote의 스타일을 초기화하지 않음 */
  border-left: 4px solid rgba(0,0,0,0.3);
  padding-left: 1rem;
  
}