#viewer {
  display: flex;
  flex-direction: column;
}

#viewer blockquote {
  font-size: 20px;
  font-weight: 600;
  border-left: 3px solid #ccc;
  padding-left: 10px;
  color: #242a35;
  margin-bottom: 20px;
  line-height: 1;
}

#viewer > p {
  color: #717c8d;
  font-weight: 500;
  line-height: 1.6;
}
