/* App.css 또는 별도의 CSS 파일 */
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-medium {
  font-size: 1em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}

.editor-viewer h1 {
  font-size: 2.5em;
  font-weight: bold;
}

.editor-viewer h2 {
  font-size: 2em;
  font-weight: bold;
}

.editor-viewer h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.editor-viewer blockquote {
  border-left: 4px solid #d0def8;
  padding-left: 1em;
  margin-left: 0;
}

.editor-viewer {
  color: #26252e;
}
